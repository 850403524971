import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';

interface ICommonSearchProps {
    searchValue?: string,
    cleanAfterSearch: boolean,
    onSearch: (searchValue: string) => void;
}

const CommonSearch: FC<ICommonSearchProps> = (props) => {
    const { searchValue, onSearch, cleanAfterSearch } = props;
    const [value, setValue] = useState<string>('');

    useEffect(() => {
        searchValue && setValue(searchValue);
    }, [searchValue]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Enter' || e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleSearch = () => {
        onSearch(value.trim());
        cleanAfterSearch && setValue('');
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    return (
        <Box display={'flex'} alignItems={'center'} mx={2} px={2}>
            <TextField value={value} fullWidth onChange={handleChange} onKeyDown={handleKeyDown} InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Box display={'flex'} alignItems={'center'} mx={1}>
                            <Search onClick={handleSearch} />
                        </Box>
                    </InputAdornment>
                ),
                sx: { borderRadius: 100 }
            }} />
        </Box>
    );
};

export default CommonSearch;
