import { KeywordSubscriptionControllerApi, KeywordSubscriptionNotificationControllerApi, UnitsControllerApi } from 'revengerz-oss-client';
import axiosConf from './axiosConf';

let unitsApi: UnitsControllerApi;
let keywordSubscriptionApi: KeywordSubscriptionControllerApi;
let keywordSubscriptionNotificationApi: KeywordSubscriptionNotificationControllerApi;

export default {
    keywordSubscriptionApi(): KeywordSubscriptionControllerApi {
        if (!keywordSubscriptionApi) {
            updateKeywordSubscriptionApi();
        }

        return keywordSubscriptionApi;
    },

    keywordSubscriptionNotificationApi(): KeywordSubscriptionNotificationControllerApi {
        if (!keywordSubscriptionNotificationApi) {
            updateKeywordSubscriptionNotificationApi();
        }

        return keywordSubscriptionNotificationApi;
    },

    unitsApi(): UnitsControllerApi {
        if (!unitsApi) {
            updateUnitsApi();
        }

        return unitsApi;
    },

    update() {
        updateUnitsApi();
        updateKeywordSubscriptionApi();
    },
};

function updateKeywordSubscriptionApi() {
    keywordSubscriptionApi = new KeywordSubscriptionControllerApi(void 0, process.env.REACT_APP_REVENGERZ_OSS_CLIENT_API_URL, axiosConf.instance());
}

function updateKeywordSubscriptionNotificationApi() {
    keywordSubscriptionNotificationApi = new KeywordSubscriptionNotificationControllerApi(
        void 0,
        process.env.REACT_APP_REVENGERZ_OSS_CLIENT_API_URL,
        axiosConf.instance()
    );
}

function updateUnitsApi() {
    unitsApi = new UnitsControllerApi(void 0, process.env.REACT_APP_REVENGERZ_OSS_CLIENT_API_URL, axiosConf.instance());
}
