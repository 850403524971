import type { FC } from 'react';
import { EncumbranceSheetC, LrEntry, LrEntryGroup } from 'revengerz-oss-client';
import { Box, Card, CardContent } from '@mui/material';
import CustomCardHeader from '../shared/CustomCardHeader';
import '../../styles/shared.css';
import LrEntryCard from './LrEntryCard';
import LabelDescriptionPair from '../shared/LabelDescriptionPair';

interface IUnitEncumbranceSheetCCardProps {
    encumbranceSheetC: EncumbranceSheetC;
}

const UnitEncumbranceSheetCCard: FC<IUnitEncumbranceSheetCCardProps> = (props) => {
    const { encumbranceSheetC } = props;

    return (
        <Card className={'content-card'}>
            <CustomCardHeader text={'Teretovnica'} />
            <CardContent>
                <Box>
                    {encumbranceSheetC.lrEntryGroups?.map((lrEntryGroup: LrEntryGroup, index: number) => (
                        <Card key={index} className={'content-card'}>
                            <CardContent>
                                {lrEntryGroup.description && <LabelDescriptionPair description={lrEntryGroup.description} />}
                                {lrEntryGroup.lrEntries?.map((lrEntry: LrEntry) => <LrEntryCard key={lrEntry.orderNumber} lrEntry={lrEntry} />)}
                            </CardContent>
                        </Card>
                    ))}
                    {encumbranceSheetC.lrEntries?.map((lrEntry: LrEntry) => <LrEntryCard key={lrEntry.lrEntryId} lrEntry={lrEntry} />)}
                </Box>
            </CardContent>
        </Card>
    );
};

export default UnitEncumbranceSheetCCard;
