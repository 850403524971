import {
    FindSubscriptionsSortDirectionEnum,
    FindSubscriptionsSortParameterEnum,
    KeywordSubscriptionIn,
    KeywordSubscriptionUpdate,
    PageableListKeywordSubscriptionDetails,
} from 'revengerz-oss-client';
import apis from '../auth/apis';
import toast from 'react-hot-toast';
import { KeywordSubscriptionDetails } from 'revengerz-oss-client/src/api';

export const findSubscriptions = async (
    pageNumber: number,
    pageSize: number,
    searchString: string,
    sortParameter: FindSubscriptionsSortParameterEnum,
    sortDirection: FindSubscriptionsSortDirectionEnum
): Promise<PageableListKeywordSubscriptionDetails> => {
    return apis
        .keywordSubscriptionApi()
        .findSubscriptions(pageNumber, pageSize, sortParameter, sortDirection, searchString)
        .then((response) => response.data)
        .catch((error) => {
            toast.error('Oops. There seems to be an error.');
            throw error;
        });
};

export const addSubscription = async (request: KeywordSubscriptionIn): Promise<KeywordSubscriptionDetails> => {
    return apis
        .keywordSubscriptionApi()
        .addSubscription(request)
        .then((response) => response.data)
        .catch((error) => {
            toast.error('Oops. There seems to be an error.');
            throw error;
        });
};

export const editSubscription = async (subscriptionUuid: string, request: KeywordSubscriptionUpdate): Promise<KeywordSubscriptionDetails> => {
    return apis
        .keywordSubscriptionApi()
        .updateSubscription(subscriptionUuid, request)
        .then((response) => response.data)
        .catch((error) => {
            toast.error('Oops. There seems to be an error.');
            throw error;
        });
};

export const deleteSubscription = async (subscriptionUuid: string): Promise<void> => {
    return apis
        .keywordSubscriptionApi()
        .deleteSubscription(subscriptionUuid)
        .then((response) => response.data)
        .catch((error) => {
            toast.error('Oops. There seems to be an error.');
            throw error;
        });
};
