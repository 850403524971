import type { FC } from 'react';
import { ReactNode } from 'react';
import { KeywordNotificationChangeTypeEnum, KeywordSubscriptionNotification } from 'revengerz-oss-client';
import { Box, Button, Card, CardContent, Chip } from '@mui/material';
import LabelValuePair from '../shared/LabelValuePair';
import { ProtectedRoutes } from '../../routes';
import '../../styles/shared.css';
import { useNavigate } from 'react-router-dom';
import { ArrowRight, Delete, MarkAsUnread } from '@mui/icons-material';
import { deleteSubscriptionNotification, markNotificationAsRead } from '../../api/keyword-subscription-notification';

interface INotificationInfoCardProps {
    keywordSubscriptionNotification: KeywordSubscriptionNotification,
    onUpdated: () => void
}

const NotificationInfoCard: FC<INotificationInfoCardProps> = (props) => {
    const { keywordSubscriptionNotification, onUpdated } = props;

    const navigate = useNavigate();

    const getChip = (): ReactNode => {
        const type = keywordSubscriptionNotification.notification.changeType;
        if (type === KeywordNotificationChangeTypeEnum.Modified) {
            return <Chip color={'warning'} label={'Izmijenjeno'} size={'small'} />;
        }

        if (type === KeywordNotificationChangeTypeEnum.Deleted) {
            return <Chip color={'error'} label={'Obrisano'} size={'small'} />;
        }

        return <Chip color={'success'} label={'Kreirano'} size={'small'} />;
    };

    const getDate = (): string => {
        const date = new Date(keywordSubscriptionNotification.createdDate);
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}.`;
    };

    const showUnitDetails = (shouldOpenInANewTab: boolean) => {
        if (shouldOpenInANewTab) {
            openDetailsInANewTab();
            return;
        }
        navigate(ProtectedRoutes.unit_details_route.path.replace(':lrUnitId', keywordSubscriptionNotification.notification.unitSimple.lrUnitId.toString()));
        markAsRead();
    };

    const openDetailsInANewTab = () => {
        window.open(ProtectedRoutes.unit_details_route.path.replace(':lrUnitId', keywordSubscriptionNotification.notification.unitSimple.lrUnitId.toString()), '_blank');
        markAsRead();
    };

    const deleteNotification = () => {
        deleteSubscriptionNotification(keywordSubscriptionNotification.uuid)
            .then(
                () => onUpdated()
            );
    };

    const markAsRead = () => {
        markNotificationAsRead(keywordSubscriptionNotification.uuid)
            .then(
                () => onUpdated()
            );
    };

    return (
        <Card
            key={keywordSubscriptionNotification.uuid}
            className={'content-card'}>
            <CardContent>
                <Box display="flex" justifyContent="space-between">
                    <Box flex={10} pr={8}>
                        <Box display={'flex'} py={2} px={1}>
                            {!keywordSubscriptionNotification.read && <Box ml={2}><Chip size={'small'} label={'Novo'} color={'error'} /></Box>}
                            <Box ml={2}>{getChip()}</Box>
                        </Box>
                        <Box>
                            <LabelValuePair label={'Datum'} value={getDate()} />
                            <LabelValuePair label={'Pretplata'}
                                            value={keywordSubscriptionNotification.subscription.keyword + ' - ' + keywordSubscriptionNotification.subscription.description} />
                            <LabelValuePair label={'Općinski sud / ZK odjel'} value={keywordSubscriptionNotification.notification.unitSimple.institutionName} />
                            <LabelValuePair label={'Katastarska općina'} value={keywordSubscriptionNotification.notification.unitSimple.mainBookName} />
                            <LabelValuePair
                                label={keywordSubscriptionNotification.notification.unitSimple.lrUnitId > 2500000 ? 'Broj ZK uloška' : 'Broj KPU poduloška'}
                                value={keywordSubscriptionNotification.notification.unitSimple.lrUnitNumber}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" flex={2} pt={1} my={'auto'}>
                        <Button
                            variant="outlined"
                            sx={{ mb: 1 }}
                            startIcon={<ArrowRight />}
                            onClick={(e) => showUnitDetails(e.ctrlKey)}
                            onAuxClick={() => openDetailsInANewTab()}
                        >
                            Detalji
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            sx={{ mb: 1 }}
                            startIcon={<Delete />}
                            onClick={deleteNotification}
                        >
                            Izbriši
                        </Button>
                        {!keywordSubscriptionNotification.read &&
                            <Button
                                variant="outlined"
                                color="secondary"
                                sx={{ mb: 1 }}
                                startIcon={<MarkAsUnread />}
                                onClick={markAsRead}
                            >
                                Označi kao pročitano
                            </Button>
                        }
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default NotificationInfoCard;
