import type { FC } from 'react';

const SplashScreen: FC = () => {
    return (
        <div>
            Splash
        </div>
    );
};

export default SplashScreen;