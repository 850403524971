import ReactDOM from 'react-dom/client';
import App from './App';
import { StyledEngineProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <StyledEngineProvider>
        {/*<SettingsProvider>*/}
        <BrowserRouter>
            {/*<ErrorHandler>*/}
            <App />
            {/*</ErrorHandler>*/}
        </BrowserRouter>
        {/*</SettingsProvider>*/}
    </StyledEngineProvider>
);
