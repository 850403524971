import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { searchUnits } from '../../api/units';
import { PageableListUnitSimple } from 'revengerz-oss-client';
import { Box, Chip, Typography } from '@mui/material';
import UnitsList from '../../components/units/UnitsList';
import { ListAlt } from '@mui/icons-material';
import EmptyState from '../../components/shared/EmptyState';
import CircularProgressShared from '../../components/shared/CircularProgressShared';
import ListPagination from '../../components/units/ListPagination';
import CommonSearch from '../../components/shared/CommonSearch';

interface SearchProps {
    pageNumber: number,
    pageSize: number,
    searchValues: string[]
}

const UnitsPage: FC = () => {
    const [pageableUnits, setPageableUnits] = useState<PageableListUnitSimple>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchProps, setSearchProps] = useState<SearchProps>({ pageNumber: 0, pageSize: 0, searchValues: [] });

    useEffect(() => {
        const searchPropsJson = localStorage.getItem('UnitsPageSearchProps');
        setSearchProps(
            searchPropsJson ?
                JSON.parse(searchPropsJson) :
                { pageNumber: 1, pageSize: 10, searchValues: [] }
        );
    }, []);

    const search = useCallback((searchProps: SearchProps) => {
        setIsLoading(true);
        searchUnits(searchProps.pageNumber - 1, searchProps.pageSize, searchProps.searchValues)
            .then((pageableListUnitSimple: PageableListUnitSimple) => {
                setPageableUnits(pageableListUnitSimple);
                localStorage.setItem('UnitsPageSearchProps', JSON.stringify(searchProps));
            })
            .finally(
                () => setIsLoading(false)
            );
    }, []);

    useEffect(() => {
        searchProps.pageNumber && search(searchProps);
    }, [searchProps, search]);

    const handleSearch = (searchValue: string) => {
        if (searchValue) {
            const svs = [...searchProps.searchValues, searchValue];
            setSearchProps(
                { ...searchProps, searchValues: svs, pageNumber: 1 }
            );
        }
    };

    const handleSizeSelect = (value: number) => {
        setSearchProps(
            { ...searchProps, pageSize: value, pageNumber: 1 }
        );
    };

    const handlePageClick = (value: number) => {
        setSearchProps(
            { ...searchProps, pageNumber: value }
        );
    };

    const handleDeleteSearchValue = (index: number) => {
        const svs = searchProps.searchValues;
        svs.splice(index, 1);
        setSearchProps(
            { ...searchProps, searchValues: svs, pageNumber: 1 }
        );
    };

    return (
        <Box>
            <Box m={1} p={2}>
                <Typography variant={'h5'}>Pretraga po pojmu</Typography>
            </Box>
            <CommonSearch cleanAfterSearch={true} onSearch={handleSearch} />
            <Box mx={2} px={2} my={2}>
                {searchProps.searchValues.map((sv, index) => (
                    <Box display={'inline-flex'} key={index} ml={1} mt={1}>
                        <Chip label={sv} onDelete={() => handleDeleteSearchValue(index)} />
                    </Box>
                ))}
            </Box>
            <Box m={1} p={2}>
                {isLoading ? (
                    <CircularProgressShared />
                ) : pageableUnits && pageableUnits.total.elements > 0 ? (
                    <Box>
                        <UnitsList units={pageableUnits.hits} />
                        <ListPagination
                            onPageClick={handlePageClick}
                            onSizeSelect={handleSizeSelect}
                            totalElements={pageableUnits.total.elements}
                            pageNumber={searchProps.pageNumber}
                            pageSize={searchProps.pageSize}
                        />
                    </Box>
                ) : (
                    <EmptyState message={'Nema pronađenih rezultata'} icon={<ListAlt />} />
                )}
            </Box>
        </Box>
    );
};

export default UnitsPage;
