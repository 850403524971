import { Box, Button, Typography } from '@mui/material';
import type { FC } from 'react';
import { useNavigate } from 'react-router';
import logo from '../../assets/img/2961313.png';

const Login: FC = () => {
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/refresh');
    };

    const handleContact = () => {
        console.log('Contacted');
    };

    return (
        <Box>
            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'center' }}>
                <Box my={4}>
                    <img src={logo} alt={'Logo'} style={{ maxWidth: '150px' }} />
                </Box>
                <Box my={4}>
                    <Typography variant="h4" gutterBottom>
                        Dobro došli na OSS registar
                    </Typography>
                </Box>
                <Box my={4} alignItems={'center'} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                    <Button size={'large'} variant={'outlined'} onClick={handleLogin} sx={{ my: 2 }}>
                        Prijava
                    </Button>
                    <Button size={'large'} variant={'outlined'} color={'info'} onClick={handleContact} sx={{ my: 2 }}>
                        Kontaktirajte nas
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Login;
