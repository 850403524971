import type { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

interface IConfirmationDialogProps {
    opened: boolean;
    title: string;
    message: string;
    onClose: () => void;
    onConfirm: (entityUuid?: string) => void;
    entityUuid?: string;
}

const ConfirmationDialog: FC<IConfirmationDialogProps> = (props) => {
    const { opened, title, message, onClose, onConfirm, entityUuid } = props;

    return (
        <Dialog open={opened} onClose={() => onClose()}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Typography variant={'body1'}>{message}</Typography>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <Button sx={{ mr: 2 }} onClick={() => onClose()}>
                    Odustani
                </Button>
                <Button variant={'contained'} onClick={() => onConfirm(entityUuid)}>
                    Potvrdi
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
