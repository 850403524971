import type { FC } from 'react';
import { UnitSimple } from 'revengerz-oss-client';
import { Box } from '@mui/material';
import UnitSimpleCard from './UnitSimpleCard';

interface IUnitsListProps {
    units: UnitSimple[];
}

const UnitsList: FC<IUnitsListProps> = (props) => {
    const { units } = props;

    return (
        <Box>
            <Box>
                {units.map((unitSimple: UnitSimple, index: number) => (
                    <Box key={index}>
                        <UnitSimpleCard unitSimple={unitSimple} />
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default UnitsList;
