import type { FC } from 'react';
import { LrEntrySimple } from 'revengerz-oss-client';
import { Box } from '@mui/material';
import LabelValuePair from '../shared/LabelValuePair';
import LabelDescriptionPair from '../shared/LabelDescriptionPair';

interface ILrEntrySimpleCardProps {
    lrEntrySimple: LrEntrySimple;
}

const LrEntrySimpleCard: FC<ILrEntrySimpleCardProps> = (props) => {
    const { lrEntrySimple } = props;

    return (
        <Box>
            {lrEntrySimple.orderNumber && <LabelValuePair label={'Redni broj upisa'} value={lrEntrySimple.orderNumber} />}
            {lrEntrySimple.description && <LabelDescriptionPair label={'Sadržaj upisa'} description={lrEntrySimple.description} />}
        </Box>
    );
};

export default LrEntrySimpleCard;
