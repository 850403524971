import type { FC } from 'react';
import { Box } from '@mui/material';
import EmptyState from '../../components/shared/EmptyState';
import { Engineering } from '@mui/icons-material';

const PeoplePage: FC = () => {
    return (
        <Box>
            <EmptyState message={'Reversal in progress...'} icon={<Engineering />} />
        </Box>
    );
};

export default PeoplePage;
