import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Box, Button, MenuItem, Select, Typography } from '@mui/material';
import { Add, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp, ListAlt } from '@mui/icons-material';
import {
    FindSubscriptionsSortDirectionEnum,
    FindSubscriptionsSortParameterEnum,
    KeywordSubscriptionIn,
    KeywordSubscriptionUpdate,
    PageableListKeywordSubscriptionDetails,
} from 'revengerz-oss-client';
import { addSubscription, deleteSubscription, editSubscription, findSubscriptions } from '../../api/keyword-subscription';
import { KeywordSubscriptionDetails } from 'revengerz-oss-client/src/api';
import SubscriptionCard from '../../components/subscriptions/SubscriptionCard';
import CircularProgressShared from '../../components/shared/CircularProgressShared';
import AddSubscriptionDialog from '../../components/subscriptions/AddSubscriptionDialog';
import CommonSearch from '../../components/shared/CommonSearch';
import ListPagination from '../../components/units/ListPagination';
import EmptyState from '../../components/shared/EmptyState';
import ConfirmationDialog from '../../components/shared/ConfirmationDialog';
import EditSubscriptionDialog from '../../components/subscriptions/EditSubscriptionDialog';

interface SearchProps {
    pageNumber: number,
    pageSize: number,
    searchValue: string,
    sortDirection: FindSubscriptionsSortDirectionEnum,
    sortParameter: FindSubscriptionsSortParameterEnum
}

const SubscriptionsPage: FC = () => {
    const [pageableListKeywordSubscriptionDetails, setPageableListKeywordSubscriptionDetails] = useState<PageableListKeywordSubscriptionDetails>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchProps, setSearchProps] = useState<SearchProps>({
        pageNumber: 0,
        pageSize: 0,
        searchValue: '',
        sortDirection: 'ASC',
        sortParameter: 'KEYWORD'
    });
    const [addSubscriptionDialogOpened, setAddSubscriptionDialogOpened] = useState<boolean>(false);
    const [subscriptionForDelete, setSubscriptionForDelete] = useState<string>();
    const [confirmationDialogOpened, setConfirmationDialogOpened] = useState<boolean>(false);
    const [editSubscriptionDialogOpened, setEditSubscriptionDialogOpened] = useState<boolean>(false);
    const [editingKeywordSubscription, setEditingKeywordSubscription] = useState<KeywordSubscriptionDetails>();

    useEffect(() => {
        const searchPropsJson = localStorage.getItem('SubscriptionsPageSearchProps');
        setSearchProps(
            searchPropsJson ?
                JSON.parse(searchPropsJson) :
                { pageNumber: 1, pageSize: 10, searchValue: '', sortDirection: 'ASC', sortParameter: 'KEYWORD' }
        );
    }, []);

    const search = useCallback((searchProps: SearchProps) => {
        setIsLoading(true);
        findSubscriptions(searchProps.pageNumber - 1, searchProps.pageSize, searchProps.searchValue, searchProps.sortParameter, searchProps.sortDirection)
            .then((response: PageableListKeywordSubscriptionDetails) => {
                setPageableListKeywordSubscriptionDetails(response);
                localStorage.setItem('SubscriptionsPageSearchProps', JSON.stringify(searchProps));
            })
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        searchProps.pageNumber && search(searchProps);
    }, [searchProps, search]);

    const handleSearch = (searchValue: string) => {
        setSearchProps(
            { ...searchProps, searchValue: searchValue, pageNumber: 1 }
        );
    };

    const handleSizeSelect = (value: number) => {
        setSearchProps(
            { ...searchProps, pageSize: value, pageNumber: 1 }
        );
    };

    const handlePageClick = (value: number) => {
        setSearchProps(
            { ...searchProps, pageNumber: value }
        );
    };

    const handleOpenAddSubscriptionDialog = () => {
        setAddSubscriptionDialogOpened(true);
    };

    const handleCloseAddSubscriptionDialog = (keywordSubscriptionIn?: KeywordSubscriptionIn) => {
        setAddSubscriptionDialogOpened(false);
        if (keywordSubscriptionIn) {
            setIsLoading(true);
            addSubscription(keywordSubscriptionIn)
                .then((value: KeywordSubscriptionDetails) => {
                    pageableListKeywordSubscriptionDetails?.hits.push(value);
                })
                .finally(() => setIsLoading(false));
        }
    };

    const handleDeleteSubscriptionClick = (subscriptionUuid: string) => {
        setSubscriptionForDelete(subscriptionUuid);
        setConfirmationDialogOpened(true);
    };

    const handleCloseConfirmationDialog = () => {
        setSubscriptionForDelete(undefined);
        setConfirmationDialogOpened(false);
    };

    const handleDeleteSubscription = (subscriptionUuid?: string) => {
        if (subscriptionUuid) {
            deleteSubscription(subscriptionUuid).then(() => {
                search(searchProps);
            });
        }

        setConfirmationDialogOpened(false);
    };

    const handleEditSubscriptionClick = (keywordSubscriptionDetails: KeywordSubscriptionDetails) => {
        setEditingKeywordSubscription(keywordSubscriptionDetails);
        setEditSubscriptionDialogOpened(true);
    };

    const handleCloseEditSubscriptionDialog = (keywordSubscriptionUpdate?: KeywordSubscriptionUpdate) => {
        if (keywordSubscriptionUpdate && editingKeywordSubscription) {
            editSubscription(editingKeywordSubscription.subscription.uuid, keywordSubscriptionUpdate).then(() =>
                search(searchProps)
            );
        }

        setEditSubscriptionDialogOpened(false);
    };

    const handleClickSortDirection = (sortDirection: FindSubscriptionsSortDirectionEnum) => {
        setSearchProps(
            { ...searchProps, sortDirection: sortDirection }
        );
    };

    const handleChangeSortParameter = (value: FindSubscriptionsSortParameterEnum) => {
        setSearchProps(
            { ...searchProps, sortParameter: value }
        );
    };

    return (
        <Box>
            <Box m={1} p={2} display={'flex'} justifyContent={'space-between'}>
                <Box>
                    <Typography variant={'h5'}>Pretplate na izmjenu podataka</Typography>
                </Box>
                <Box>
                    <Button variant={'contained'} startIcon={<Add />} size={'small'} onClick={handleOpenAddSubscriptionDialog}>
                        Dodaj
                    </Button>
                </Box>
            </Box>
            <CommonSearch cleanAfterSearch={false} searchValue={searchProps.searchValue} onSearch={handleSearch} />
            <Box display={'flex'} alignItems={'center'} mx={2} pt={4} px={2}>
                <Box flex={11}></Box>
                <Box flex={1} ml={'auto'}>
                    <Select fullWidth size={'small'} value={searchProps.sortParameter}
                            onChange={(e) => handleChangeSortParameter(e.target.value as FindSubscriptionsSortParameterEnum)}>
                        <MenuItem value={FindSubscriptionsSortParameterEnum.CreatedDate}>Datum</MenuItem>
                        <MenuItem value={FindSubscriptionsSortParameterEnum.Keyword}>OIB</MenuItem>
                        <MenuItem value={FindSubscriptionsSortParameterEnum.Description}>Opis</MenuItem>
                    </Select>
                </Box>
                <Box px={2} display={'flex'} alignItems={'center'}>
                    {
                        searchProps.sortDirection === FindSubscriptionsSortDirectionEnum.Desc ?
                            <KeyboardDoubleArrowDown onClick={() => handleClickSortDirection(FindSubscriptionsSortDirectionEnum.Asc)} /> :
                            <KeyboardDoubleArrowUp onClick={() => handleClickSortDirection(FindSubscriptionsSortDirectionEnum.Desc)} />
                    }
                </Box>
            </Box>
            <Box m={1} px={2}>
                {isLoading ? (
                    <CircularProgressShared />
                ) : (
                    <Box>
                        {pageableListKeywordSubscriptionDetails && pageableListKeywordSubscriptionDetails.total.elements ? (
                            <Box>
                                {pageableListKeywordSubscriptionDetails?.hits.map((keywordSubscriptionDetails: KeywordSubscriptionDetails) => (
                                    <SubscriptionCard
                                        subscriptionDetails={keywordSubscriptionDetails}
                                        key={keywordSubscriptionDetails.subscription.uuid}
                                        onDeleteSubscriptionClick={() => handleDeleteSubscriptionClick(keywordSubscriptionDetails.subscription.uuid)}
                                        onEditSubscriptionClick={() => handleEditSubscriptionClick(keywordSubscriptionDetails)}
                                    />
                                ))}
                                <ListPagination
                                    onPageClick={handlePageClick}
                                    onSizeSelect={handleSizeSelect}
                                    totalElements={pageableListKeywordSubscriptionDetails.total.elements}
                                    pageNumber={searchProps.pageNumber}
                                    pageSize={searchProps.pageSize}
                                />
                            </Box>
                        ) : (
                            <EmptyState message={'Nema pronađenih rezultata'} icon={<ListAlt />} />
                        )}
                    </Box>
                )}
            </Box>
            <AddSubscriptionDialog opened={addSubscriptionDialogOpened} onClose={handleCloseAddSubscriptionDialog} />
            {editingKeywordSubscription && (
                <EditSubscriptionDialog
                    opened={editSubscriptionDialogOpened}
                    onClose={(e) => handleCloseEditSubscriptionDialog(e)}
                    keywordSubscriptionDetails={editingKeywordSubscription.subscription}
                />
            )}
            <ConfirmationDialog
                opened={confirmationDialogOpened}
                title={'Brisanje pretplate'}
                message={
                    'Jeste li sigurni da želite obrisati pretplatu na ovu ključnu riječ? Nakon brisanja sve pristigle notifikacije biti će trajno obrisane.'
                }
                entityUuid={subscriptionForDelete}
                onClose={handleCloseConfirmationDialog}
                onConfirm={(e) => handleDeleteSubscription(e)}
            />
        </Box>
    );
};

export default SubscriptionsPage;
