import type { FC } from 'react';
import { useState } from 'react';
import { LrOwnerSimple, UnitPdfUrl, UnitSimple } from 'revengerz-oss-client';
import { Box, Button, Card, CardActions, CardContent, Theme, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ProtectedRoutes } from '../../routes';
import '../../styles/shared.css';
import LabelValuePair from '../shared/LabelValuePair';
import { ArrowRight, PictureAsPdf } from '@mui/icons-material';
import { getUnitPdfUrl } from '../../api/units';
import LrOwnerSimpleValue from './LrOwnerSimpleValue';

interface IUnitCardProps {
    unitSimple: UnitSimple;
}

const UnitSimpleCard: FC<IUnitCardProps> = (props) => {
    const { unitSimple } = props;
    const [pdfButtonsDisabled, setPdfButtonsDisabled] = useState<boolean>(false);

    const navigate = useNavigate();

    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    const showUnitDetails = (shouldOpenInANewTab: boolean) => {
        if (shouldOpenInANewTab) {
            openDetailsInANewTab();
            return;
        }
        navigate(ProtectedRoutes.unit_details_route.path.replace(':lrUnitId', unitSimple.lrUnitId.toString()));
    };

    const openDetailsInANewTab = () => {
        window.open(ProtectedRoutes.unit_details_route.path.replace(':lrUnitId', unitSimple.lrUnitId.toString()), '_blank');
    };

    const openUnitPdf = (historical: boolean) => {
        setPdfButtonsDisabled(true);
        getUnitPdfUrl(unitSimple.lrUnitId, historical)
            .then((unitPdfUrl: UnitPdfUrl) => {
                window.open(unitPdfUrl.url, 'bla');
                setPdfButtonsDisabled(false);
            })
            .catch(() => setPdfButtonsDisabled(false));
    };

    return (
        <Card className="content-card">
            <CardContent>
                <Box display="flex" justifyContent="space-between">
                    <Box flex={10} pr={8}>
                        <LabelValuePair label={'Općinski sud / ZK odjel'} value={unitSimple.institutionName} />
                        <LabelValuePair label={'Katastarska općina'} value={unitSimple.mainBookName} />
                        <LabelValuePair label={unitSimple.lrUnitId > 2500000 ? 'Broj ZK uloška' : 'Broj KPU poduloška'} value={unitSimple.lrUnitNumber} />
                        {unitSimple.matchedOwners && <Box className="label-value-box">
                            <Box className="label-value-box-label">
                                <Typography variant="overline">Pronađene osobe</Typography>
                            </Box>
                            <Box>
                                {unitSimple.matchedOwners.slice(0, 5).map((matchedOwner: LrOwnerSimple) => (
                                    <LrOwnerSimpleValue lrOwner={matchedOwner}
                                                        key={(matchedOwner.taxNumber ? matchedOwner.taxNumber : '') + (matchedOwner.name ? matchedOwner.name : '') + (matchedOwner.address ? matchedOwner.address : '')} />
                                ))}
                                {unitSimple.matchedOwners.length > 5 &&
                                    <Box>+ još {unitSimple.matchedOwners.length - 5}</Box>
                                }
                            </Box>
                        </Box>}
                    </Box>
                    {lgUp && (
                        <Box display="flex" flexDirection="column" flex={2} pt={1} my={'auto'}>
                            <Button
                                variant="outlined"
                                sx={{ mb: 1 }}
                                startIcon={<ArrowRight />}
                                onClick={(e) => showUnitDetails(e.ctrlKey)}
                                onAuxClick={() => openDetailsInANewTab()}
                            >
                                Detalji
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                sx={{ mb: 1 }}
                                startIcon={<PictureAsPdf />}
                                onClick={() => openUnitPdf(false)}
                                onAuxClick={() => openUnitPdf(false)}
                                disabled={pdfButtonsDisabled}
                            >
                                PDF
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                sx={{ mb: 1 }}
                                startIcon={<PictureAsPdf />}
                                onClick={() => openUnitPdf(true)}
                                onAuxClick={() => openUnitPdf(false)}
                                disabled={pdfButtonsDisabled}
                            >
                                Povijesni PDF
                            </Button>
                        </Box>
                    )}
                </Box>
            </CardContent>

            {!lgUp && (
                <CardActions>
                    <Button variant="outlined" onClick={(e) => showUnitDetails(e.ctrlKey)}>
                        Detalji
                    </Button>
                </CardActions>
            )}
        </Card>
    );
};

export default UnitSimpleCard;
