import axios, { AxiosInstance, AxiosResponse } from 'axios';
import auth from './auth';
import apis from './apis';

let currentInstance: AxiosInstance;

export default {
    instance() {
        if (currentInstance == null) {
            this.update();
        }
        return currentInstance;
    },

    update() {
        currentInstance = axios.create({
            headers: {
                'Authorization': 'Bearer ' + auth.getToken()?.accessToken
            }
        });
        currentInstance.interceptors.response.use(
            response => response,
            error => {
                const status = error.response ? error.response.status : null;

                if (status === 401) {
                    return refreshToken()
                        .then(() => {
                            error.config.headers['Authorization'] = 'Bearer ' + auth.getToken()?.accessToken;
                            error.config.baseURL = undefined;
                            return axios.request(error.config);
                        });
                }

                return Promise.reject(error);
            }
        );
        apis.update();
    }
};

const baseAxiosInstance: AxiosInstance = axios.create();

let refreshPromise: Promise<void> | null = null;

export const refreshRequest = async (): Promise<void> => {
    auth.setIsRefreshInProgress(true);
    return baseAxiosInstance
        .post(
            `${process.env.REACT_APP_ACCESS_TOKEN_URL}`,
            new URLSearchParams({
                client_id: process.env.REACT_APP_CLIENT_ID || '',
                grant_type: 'refresh_token',
                refresh_token: auth.getToken()?.refreshToken || ''
            })
        )
        .then((response: AxiosResponse) => {
            const token = {
                accessToken: response.data.access_token,
                refreshToken: response.data.refresh_token,
                refreshExpiresIn: response.data.refresh_expires_in
            };
            auth.setToken(token);
            return Promise.resolve();
        })
        .catch((err) => {
            auth.clearLoginData();
            window.location.assign(process.env.REACT_APP_REDIRECT_URL + '/login');
            return Promise.reject(err);
        })
        .finally(() => {
            auth.setIsRefreshInProgress(false);
            refreshPromise = null;
        });
};

export function refreshToken(): Promise<void> {
    if (refreshPromise && auth.getIsRefreshInProgress()) {
        return refreshPromise;
    }

    refreshPromise = refreshRequest();
    return refreshPromise;
}
