import type { FC } from 'react';
import { Box } from '@mui/material';
import LabelValuePair from '../shared/LabelValuePair';

interface IParcelAreaValueProps {
    area?: string;
    areaInAcres?: string;
    areaInHvat?: string;
}

const ParcelAreaValue: FC<IParcelAreaValueProps> = (props) => {
    const { area, areaInAcres, areaInHvat } = props;

    const areaValue = (): string => {
        const areas: string[] = [];

        if (area) {
            const aV = area + ' m2';
            areas.push(aV);
        }

        if (areaInAcres) {
            const aV = areaInAcres + ' jutro';
            areas.push(aV);
        }

        if (areaInHvat) {
            const aV = areaInHvat + ' čhv';
            areas.push(aV);
        }

        return areas.join(' · ');
    };

    return (
        <Box my={1}>
            <LabelValuePair label={'Površina'} value={areaValue()} />
        </Box>
    );
};

export default ParcelAreaValue;
