import type { FC } from 'react';
import { Parcel, ParcelPart } from 'revengerz-oss-client';
import { Box, Card, CardContent } from '@mui/material';
import LabelValuePair from '../shared/LabelValuePair';
import ParcelAreaValue from './ParcelAreaValue';
import ParcelPartValue from './ParcelPartValue';
import '../../styles/shared.css';

interface ILrParcelCardProps {
    lrParcel: Parcel;
}

const LrParcelCard: FC<ILrParcelCardProps> = (props) => {
    const { lrParcel } = props;

    return (
        <Card className={'content-card'}>
            <CardContent>
                {lrParcel.parcelNumber && <LabelValuePair label={'Broj zemljišta'} value={lrParcel.parcelNumber} />}
                {(lrParcel.area || lrParcel.areaInAcres || lrParcel.areaInHvat) && (
                    <ParcelAreaValue area={lrParcel.area} areaInAcres={lrParcel.areaInAcres} areaInHvat={lrParcel.areaInHvat} />
                )}
                {lrParcel.address && <LabelValuePair label={'Oznaka zemljišta'} value={lrParcel.address} />}
                <Box>{lrParcel.parcelParts?.map((parcelPart: ParcelPart, index: number) => <ParcelPartValue key={index} parcelPart={parcelPart} />)}</Box>
            </CardContent>
        </Card>
    );
};

export default LrParcelCard;
