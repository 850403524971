import React, { useState } from 'react';
import { RequestAuthorizationCode } from 'react-oauth2-auth-code-flow';
import { AppSettings } from '../../common/app-settings';
import { NavigationHelper } from '../../utils/navigation-helper';
import { Grid } from '@mui/material';

const oAuthClient = AppSettings.OAUTH_CLIENT;
const oAuthScopes = AppSettings.OAUTH_SCOPES;

export interface IOAuthState {
    resumeUrl?: string;
}

export const OAuthRefreshPage: React.FC = () => {
    const resumeUrlFromQuery = NavigationHelper.getResumeUrlParamFromUrl(window.location.search);
    const [resumeUrl] = useState<string>(resumeUrlFromQuery);

    const oAuthState: IOAuthState = {};

    if (resumeUrlFromQuery) {
        oAuthState.resumeUrl = resumeUrl;
    }

    const logIn = (url: string): string => {
        window.location.href = url;
        return 'Refreshing';
    };

    const renderComponent = (url: string) => {
        return (
            <div>
                {logIn(url)} {'Refreshing'}
            </div>
        );
    };

    return (
        <Grid container direction="column" alignItems="center" justifyContent="center">
            <RequestAuthorizationCode
                args={{ scope: oAuthScopes }}
                oauthClient={oAuthClient}
                state={oAuthState}
                render={({ url }: { url: string }) => {
                    renderComponent(url);
                    return null;
                }}
            />
        </Grid>
    );
};
