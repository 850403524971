import { FC, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Login from '../../pages/authentication/Login';
import { Box, CircularProgress } from '@mui/material';
import auth from '../../auth/auth';

interface AuthGuardProps {
    children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
    const { children } = props;
    const location = useLocation();
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [refreshInProgress, setRefreshInProgress] = useState<boolean>(false);

    useEffect(() => {
        const checkIfLoggedIn = async () => {
            setRefreshInProgress(true);
            setIsLoggedIn(await auth.isLoggedIn());
            setRefreshInProgress(false);
        };

        if (!isLoggedIn) {
            checkIfLoggedIn().then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setRedirectLink = () => {
        window.localStorage.removeItem('redirectLink');
        if (location.pathname !== '/') {
            window.localStorage.setItem('redirectLink', location.pathname);
        }
    };

    if (!isLoggedIn) {
        setRedirectLink();
        return refreshInProgress ? (
            <Box sx={{ left: '50%', opacity: 0.6, position: 'absolute', top: '50%' }}>
                <CircularProgress size="5rem" />
            </Box>
        ) : (
            <Login />
        );
    }

    return <>{children}</>;
};

export default AuthGuard;
