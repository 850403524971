import type { ChangeEvent, FC } from 'react';
import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField, Typography } from '@mui/material';
import { KeywordSubscriptionIn } from 'revengerz-oss-client';

interface IAddSubscriptionDialogProps {
    opened: boolean;
    onClose: (keywordSubscriptionIn?: KeywordSubscriptionIn) => void;
}

const AddSubscriptionDialog: FC<IAddSubscriptionDialogProps> = (props) => {
    const [keywordSubscriptionIn, setKeywordSubscriptionIn] = useState<KeywordSubscriptionIn>({ keyword: '', description: '' });

    const { opened, onClose } = props;

    const handleCancel = () => {
        setKeywordSubscriptionIn({ keyword: '', description: '' });
        onClose();
    };

    const handleConfirm = () => {
        if (keywordSubscriptionIn) {
            onClose(keywordSubscriptionIn);
            setKeywordSubscriptionIn({ keyword: '', description: '' });
        }
    };

    const handleKeywordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setKeywordSubscriptionIn({ ...keywordSubscriptionIn, keyword: event.target.value });
    };

    const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setKeywordSubscriptionIn({ ...keywordSubscriptionIn, description: event.target.value });
    };

    return (
        <Dialog open={opened} onClose={() => onClose()}>
            <DialogTitle>Dodavanje pretplate na izmjenu podataka</DialogTitle>
            <DialogContent>
                <Typography variant={'caption'}>
                    Dodavanjem ključne riječi dolaziti će Vam notifikacija ukoliko se za tu ključnu riječ promjeni jedan od pripadajućih podataka
                </Typography>
                <FormControl fullWidth size={'small'} sx={{ marginY: 2 }}>
                    <TextField placeholder={'Ključna riječ'} value={keywordSubscriptionIn?.keyword} onChange={handleKeywordChange} />
                </FormControl>
                <FormControl fullWidth size={'small'} sx={{ marginY: 2 }}>
                    <TextField placeholder={'Opis'} value={keywordSubscriptionIn?.description} onChange={handleDescriptionChange} />
                </FormControl>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <Button sx={{ mr: 2 }} onClick={handleCancel}>Odustani</Button>
                <Button variant={'contained'} onClick={handleConfirm} disabled={keywordSubscriptionIn.keyword.length < 2}>
                    Potvrdi
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddSubscriptionDialog;
