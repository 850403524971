import type { FC } from 'react';
import { useState } from 'react';
import { AuthorizationCodeCallback } from 'react-oauth2-auth-code-flow';
import { useNavigate } from 'react-router-dom';
import { AppSettings } from '../../common/app-settings';
import { IToken } from '../../model/common/token/token';
import auth from '../../auth/auth';
import { jwtDecode } from 'jwt-decode';
import { ICustomJwtPayload, TokenHelper } from '../../utils/token-helper';
import { Button, CircularProgress, Grid } from '@mui/material';
import { ITokenClientOauth2 } from '../../model/common/token/token-client-oauth2';
import SplashScreen from '../../components/global/SplashScreen';

interface IRenderProcessingStatus {
    processing: boolean;
    error?: {
        message: string;
    };
}

const oAuthClient = AppSettings.OAUTH_CLIENT;
const oAuthScopes = AppSettings.OAUTH_SCOPES;

export const OAuthRedirectPage: FC = () => {
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(true);
    const [error, setError] = useState<string>('');

    if (error) {
        navigate('/login', { replace: true });
    }

    const handleSuccess = async (accessToken: ITokenClientOauth2) => {
        const token: IToken = {
            accessToken: accessToken.accessToken,
            expires: accessToken.expires,
            expiresIn: accessToken.data.expires_in,
            refreshExpires: new Date(Date.now() + (accessToken.data.refresh_expires_in ? accessToken.data.refresh_expires_in : 86000) * 1000),
            refreshExpiresIn: accessToken.data.refresh_expires_in,
            refreshToken: accessToken.refreshToken,
        };

        auth.setToken({
            accessToken: token.accessToken,
            refreshToken: token.refreshToken,
        });
        const decodedToken: ICustomJwtPayload = jwtDecode(token.accessToken);
        const userName: string = TokenHelper.getUsernameFromToken(decodedToken);
        auth.setUserName(userName);

        const redirectLink = window.localStorage.getItem('redirectLink') as string;
        window.localStorage.removeItem('redirectLink');
        navigate(redirectLink ?? '/home/units', { replace: true });
    };

    const handleError = () => {
        setShowLoader(false);
        const searchParams = new URLSearchParams(window.location.search);
        const error = searchParams.get('error') ?? 'login_error';
        setError(error);
    };

    const handleTryAgain = () => {
        navigate('/');
    };

    function getRenderComponent(processingData: IRenderProcessingStatus) {
        return (
            <div>
                {processingData.processing && <SplashScreen />}
                {processingData.error?.message && (
                    <>
                        <p>An error occurred: {processingData.error?.message}</p>
                        <Grid container direction="row" alignItems="center" justifyContent="center">
                            <Button onClick={handleTryAgain}>Pokušaj ponovno</Button>
                        </Grid>
                    </>
                )}
            </div>
        );
    }

    return (
        <Grid container direction="column" alignItems="center" justifyContent="center">
            {showLoader && <CircularProgress />}
            <AuthorizationCodeCallback
                oauthClient={oAuthClient}
                args={{ scope: oAuthScopes }}
                onAuthSuccess={handleSuccess}
                onAuthError={handleError}
                render={(processingData: IRenderProcessingStatus) => getRenderComponent(processingData)}
            />
        </Grid>
    );
};
