import apis from '../auth/apis';
import { PageableListUnitSimple, Unit, UnitPdfUrl } from 'revengerz-oss-client';
import toast from 'react-hot-toast';

export const searchUnits = async (pageNumber: number, pageSize: number, queryStrings: string[]): Promise<PageableListUnitSimple> => {
    return apis
        .unitsApi()
        .searchUnits(pageNumber, pageSize, undefined, queryStrings)
        .then((response) => response.data)
        .catch((error) => {
            toast.error('Oops. There seems to be an error.');
            throw error;
        });
};

export const getUnit = async (lrUnitId: number): Promise<Unit> => {
    return apis
        .unitsApi()
        .getUnit(lrUnitId)
        .then((response) => response.data)
        .catch((error) => {
            toast.error('Oops. There seems to be an error.');
            throw error;
        });
};

export const getUnitPdfUrl = async (lrUnitId: number, historical: boolean): Promise<UnitPdfUrl> => {
    return apis
        .unitsApi()
        .getUnitPdfUrl(lrUnitId, historical)
        .then((response) => response.data)
        .catch((error) => {
            toast.error('Oops. There seems to be an error.');
            throw error;
        });
};

