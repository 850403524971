import type { FC } from 'react';
import { LrBody, LrEntry, Parcel, PossessionSheet } from 'revengerz-oss-client';
import LrParcelCard from './LrParcelCard';
import { Box, Card, CardContent } from '@mui/material';
import CustomCardHeader from '../shared/CustomCardHeader';
import LrBodyCard from './LrBodyCard';
import LrEntryCard from './LrEntryCard';

interface IPossessionSheetProps {
    possessionSheet: PossessionSheet;
    title: string;
}

const PossessionSheetCard: FC<IPossessionSheetProps> = (props) => {
    const { possessionSheet, title } = props;

    return (
        <Card className={'content-card'}>
            <CustomCardHeader text={title} />
            <CardContent>
                <Box>{possessionSheet.lrBodies?.map((value: LrBody, index: number) => <LrBodyCard key={index} lrBody={value} />)}</Box>
                <Box>{possessionSheet.parcels?.map((value: Parcel, index: number) => <LrParcelCard key={index} lrParcel={value} />)}</Box>
                <Box>{possessionSheet.lrEntries?.map((value: LrEntry, index: number) => <LrEntryCard key={index} lrEntry={value} />)}</Box>
            </CardContent>
        </Card>
    );
};

export default PossessionSheetCard;
