import type { FC, ReactElement } from 'react';
import { cloneElement } from 'react';
import { Box, Typography } from '@mui/material';
import '../../styles/shared.css';
import { useNavigate } from 'react-router-dom';

export interface IMenuItem {
    icon: ReactElement;
    label: string;
    routePath?: string;
}

interface ICustomMenuItemProps {
    menuItem: IMenuItem;
}

const CustomMenuItem: FC<ICustomMenuItemProps> = (props) => {
    const { menuItem } = props;

    const navigate = useNavigate();

    const handleClick = () => {
        if (menuItem.routePath) {
            navigate(menuItem.routePath);
        }
    };

    return (
        <Box className={'menu-item'} onClick={handleClick}>
            {cloneElement(menuItem.icon, { fontSize: 'medium' })}
            <Typography variant={'caption'}>{menuItem.label}</Typography>
        </Box>
    );
};

export default CustomMenuItem;
