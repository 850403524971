import type { FC } from 'react';
import { Box, Typography } from '@mui/material';

interface ICustomCardHeaderProps {
    text: string;
}

const CustomCardHeader: FC<ICustomCardHeaderProps> = (props) => {
    const { text } = props;

    return (
        <Box m={1} p={1}>
            <Typography variant={'h6'}>{text}</Typography>
        </Box>
    );
};

export default CustomCardHeader;
