import type { FC } from 'react';
import { LrBody, LrEntry, LrOwner, LrUnitShare, OwnershipSheetB } from 'revengerz-oss-client';
import { Box, Card, CardContent } from '@mui/material';
import CustomCardHeader from '../shared/CustomCardHeader';
import LrUnitShareCard from './LrUnitShareCard';
import '../../styles/shared.css';
import LrEntryCard from './LrEntryCard';
import LrBodyCard from './LrBodyCard';
import LrOwnerValue from './LrOwnerValue';

interface IOwnershipSheetBCardProps {
    ownershipSheetB: OwnershipSheetB;
}

const OwnershipSheetBCard: FC<IOwnershipSheetBCardProps> = (props) => {
    const { ownershipSheetB } = props;

    return (
        <Card className={'content-card'}>
            <CustomCardHeader text={'Vlastovnica'} />
            <CardContent>
                <Box>
                    {ownershipSheetB.lrUnitShares?.map((lrUnitShare: LrUnitShare, index: number) => <LrUnitShareCard key={index} lrUnitShare={lrUnitShare} />)}
                </Box>
                <Box>{ownershipSheetB.lrEntries?.map((lrEntry: LrEntry) => <LrEntryCard key={lrEntry.orderNumber} lrEntry={lrEntry} />)}</Box>
                <Box>{ownershipSheetB.lrBodies?.map((lrBody: LrBody, index: number) => <LrBodyCard key={index} lrBody={lrBody} />)}</Box>
                <Box>{ownershipSheetB.lrOwners?.map((lrOwner: LrOwner) => <LrOwnerValue key={lrOwner.lrOwnerId} lrOwner={lrOwner} />)}</Box>
            </CardContent>
        </Card>
    );
};

export default OwnershipSheetBCard;
