import type { FC } from 'react';
import { Box, Card, CardContent } from '@mui/material';
import { LrBody, LrEntry, Parcel } from 'revengerz-oss-client';
import '../../styles/shared.css';
import LrEntryCard from './LrEntryCard';
import LrParcelCard from './LrParcelCard';
import LabelDescriptionPair from '../shared/LabelDescriptionPair';

interface ILrBodyProps {
    lrBody: LrBody;
}

const LrBodyCard: FC<ILrBodyProps> = (props) => {
    const { lrBody } = props;

    return (
        <Card className={'content-card'}>
            <CardContent>
                <Box className={'label-value-box-label'}>ZK tijelo {lrBody.title}</Box>
                {lrBody.lrParcels && <Box>{lrBody.lrParcels?.map((lrParcel: Parcel, index: number) => <LrParcelCard key={index} lrParcel={lrParcel} />)}</Box>}
                {lrBody.description && <LabelDescriptionPair label={'Opis'} description={lrBody.description} />}
                <Box>{lrBody.lrEntries?.map((lrEntry: LrEntry, index: number) => <LrEntryCard key={index} lrEntry={lrEntry} />)}</Box>
            </CardContent>
        </Card>
    );
};

export default LrBodyCard;
