import type { FC } from 'react';
import { AppBar, AppBarProps, Box, experimentalStyled, IconButton, Toolbar, Typography } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ProtectedRoutes, UnprotectedRoutes } from '../../routes';
import { Logout, Menu } from '@mui/icons-material';
import logo from '../../assets/img/2961313.png';
import auth from '../../auth/auth';

interface DashboardNavbarProps extends AppBarProps {
    onSidebarMobileOpen?: () => void;
}

const DashboardToolbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
    ...(theme.palette.mode === 'light' && {
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
        color: theme.palette.primary.contrastText,
    }),
    ...(theme.palette.mode === 'dark' && {
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.divider}`,
        boxShadow: 'none',
    }),
    zIndex: theme.zIndex.drawer + 100,
}));

const DashboardToolbar: FC<DashboardNavbarProps> = (props) => {
    const { onSidebarMobileOpen, ...other } = props;

    const navigate = useNavigate();

    const handleLogout = () => {
        auth.clearLoginData();
        navigate(UnprotectedRoutes.unprotected_login_route.path);
    };

    return (
        <DashboardToolbarRoot {...other}>
            <Toolbar sx={{ minHeight: 64, boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.1)' }}>
                <IconButton color="inherit" onClick={onSidebarMobileOpen} sx={{ display: { lg: 'none' } }}>
                    <Menu fontSize="small" />
                </IconButton>
                <RouterLink to={ProtectedRoutes.units_route.path}>
                    <Box display="flex" alignItems="center">
                        <Box
                            sx={{
                                '& > img': {
                                    maxHeight: '100%',
                                    width: 'auto',
                                    display: {
                                        lg: 'inline',
                                        xs: 'none',
                                    },
                                },
                                height: 60,
                                marginLeft: '5px',
                                marginRight: '10px',
                            }}>
                            <img src={logo} alt="RevengLogo" />
                        </Box>
                        <Box>
                            <Typography color="#ffffff" sx={{ fontWeight: 600, fontSize: 30 }}>
                                RevEngerz
                            </Typography>
                        </Box>
                    </Box>
                </RouterLink>
                <Box sx={{ flexGrow: 1, ml: 2 }} />
                <Box>
                    <IconButton onClick={handleLogout}>
                        <Logout />
                    </IconButton>
                </Box>
            </Toolbar>
        </DashboardToolbarRoot>
    );
};

export default DashboardToolbar;
