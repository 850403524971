import type { FC } from 'react';
import { Box, Typography } from '@mui/material';
import parse from 'html-react-parser';
import '../../styles/shared.css';

interface ILabelDescriptionPairProps {
    label?: string;
    description: string;
}

const LabelDescriptionPair: FC<ILabelDescriptionPairProps> = (props) => {
    const { description, label } = props;

    return (
        <Box p={1}>
            {label && (
                <Box className={'label-value-box-label'}>
                    <Typography variant={'overline'}>{label}</Typography>
                </Box>
            )}
            <Box>
                <Typography>{parse(description)}</Typography>
            </Box>
        </Box>
    );
};

export default LabelDescriptionPair;
