import type { FC } from 'react';
import { LrEntry, LrOwner } from 'revengerz-oss-client';
import { Box, Card, CardContent, Typography } from '@mui/material';
import LabelValuePair from '../shared/LabelValuePair';
import LrOwnerValue from './LrOwnerValue';
import LabelDescriptionPair from '../shared/LabelDescriptionPair';

interface ILrEntryCardProps {
    lrEntry: LrEntry;
}

const LrEntryCard: FC<ILrEntryCardProps> = (props) => {
    const { lrEntry } = props;

    return (
        <Card className={'content-card'}>
            <CardContent>
                {lrEntry.orderNumber && <LabelValuePair label={'Redni broj upisa'} value={lrEntry.orderNumber} />}
                {lrEntry.description && <LabelDescriptionPair label={'Sadržaj upisa'} description={lrEntry.description} />}
                {lrEntry.lrOwners && (
                    <Box className={'label-value-box'}>
                        <Box className={'label-value-box-label'}>
                            <Typography variant={'overline'}>Ime i prezime / Naziv</Typography>
                        </Box>
                        <Box>{lrEntry.lrOwners?.map((lrOwner: LrOwner, index: number) => <LrOwnerValue key={index} lrOwner={lrOwner} />)}</Box>
                    </Box>
                )}
                {lrEntry.amount && <LabelValuePair label={'Iznos'} value={lrEntry.amount} />}
            </CardContent>
        </Card>
    );
};

export default LrEntryCard;
