import type { FC } from 'react';
import { ChangeEvent, useState } from 'react';
import { KeywordSubscriptionOut } from 'revengerz-oss-client/src/api';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@mui/material';
import { KeywordSubscriptionUpdate } from 'revengerz-oss-client';

interface IEditSubscriptionDialogProps {
    opened: boolean;
    onClose: (keywordSubscriptionUpdate?: KeywordSubscriptionUpdate) => void;
    keywordSubscriptionDetails: KeywordSubscriptionOut;
}

const EditSubscriptionDialog: FC<IEditSubscriptionDialogProps> = (props) => {
    const { opened, onClose, keywordSubscriptionDetails } = props;
    const [keywordSubscriptionUpdate, setKeywordSubscriptionUpdate] = useState<KeywordSubscriptionUpdate>({
        description: keywordSubscriptionDetails.description,
    });

    const handleCancel = () => {
        setKeywordSubscriptionUpdate({
            description: keywordSubscriptionDetails.description,
        });
        onClose();
    };

    const handleConfirm = () => {
        if (keywordSubscriptionUpdate) {
            onClose(keywordSubscriptionUpdate);
            setKeywordSubscriptionUpdate({
                description: keywordSubscriptionDetails.description,
            });
        }
    };

    const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setKeywordSubscriptionUpdate({ description: event.target.value });
    };

    return (
        <Dialog open={opened} onClose={() => onClose()}>
            <DialogTitle>Izmjena pretplate na izmjenu podataka</DialogTitle>
            <DialogContent>
                <FormControl fullWidth size={'small'} sx={{ marginY: 2 }}>
                    <TextField placeholder={'Ključna riječ'} value={keywordSubscriptionDetails?.keyword} disabled />
                </FormControl>
                <FormControl fullWidth size={'small'} sx={{ marginY: 2 }}>
                    <TextField placeholder={'Opis'} value={keywordSubscriptionUpdate.description} onChange={handleDescriptionChange} />
                </FormControl>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <Button sx={{ mr: 2 }} onClick={handleCancel}>
                    Odustani
                </Button>
                {keywordSubscriptionUpdate.description && (
                    <Button
                        variant={'contained'}
                        onClick={handleConfirm}
                        disabled={keywordSubscriptionUpdate.description.length < 2 || keywordSubscriptionDetails.description === keywordSubscriptionUpdate.description}>
                        Potvrdi
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default EditSubscriptionDialog;
