import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
    FindSubscriptionNotificationsSortDirectionEnum,
    FindSubscriptionNotificationsSortParameterEnum,
    KeywordSubscriptionNotification,
    PageableListKeywordSubscriptionNotification,
} from 'revengerz-oss-client';
import { findSubscriptionNotifications } from '../../api/keyword-subscription-notification';
import CircularProgressShared from '../../components/shared/CircularProgressShared';
import NotificationInfoCard from '../../components/notifications/NotificationInfoCard';
import CommonSearch from '../../components/shared/CommonSearch';
import ListPagination from '../../components/units/ListPagination';
import EmptyState from '../../components/shared/EmptyState';
import { ListAlt } from '@mui/icons-material';

interface SearchProps {
    pageNumber: number,
    pageSize: number,
    searchValue: string,
    sortDirection: FindSubscriptionNotificationsSortDirectionEnum,
    sortParameter: FindSubscriptionNotificationsSortParameterEnum
}

const NotificationsPage: FC = () => {
    const [pageableKeywordSubscriptionNotification, setPageableKeywordSubscriptionNotification] = useState<PageableListKeywordSubscriptionNotification>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchProps, setSearchProps] = useState<SearchProps>({
        pageNumber: 0,
        pageSize: 0,
        searchValue: '',
        sortDirection: 'ASC',
        sortParameter: 'KEYWORD'
    });

    useEffect(() => {
        const searchPropsJson = localStorage.getItem('NotificationsPageSearchProps');
        setSearchProps(
            searchPropsJson ?
                JSON.parse(searchPropsJson) :
                { pageNumber: 1, pageSize: 10, searchValue: '', sortDirection: 'ASC', sortParameter: 'KEYWORD' }
        );
    }, []);

    const search = useCallback((searchProps: SearchProps) => {
        setIsLoading(true);
        findSubscriptionNotifications(searchProps.pageNumber - 1, searchProps.pageSize, searchProps.sortParameter, searchProps.sortDirection, undefined, searchProps.searchValue, undefined)
            .then((response: PageableListKeywordSubscriptionNotification) => {
                setPageableKeywordSubscriptionNotification(response);
                localStorage.setItem('NotificationsPageSearchProps', JSON.stringify(searchProps));
            })
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        searchProps.pageNumber && search(searchProps);
    }, [searchProps, search]);

    const handleSearch = (searchValue: string) => {
        setSearchProps(
            { ...searchProps, searchValue: searchValue, pageNumber: 1 }
        );
    };

    const handleSizeSelect = (value: number) => {
        setSearchProps(
            { ...searchProps, pageSize: value, pageNumber: 1 }
        );
    };

    const handlePageClick = (value: number) => {
        setSearchProps(
            { ...searchProps, pageNumber: value }
        );
    };

    const handleNotificationUpdated = () => {
        search(searchProps);
    };

    return (
        <Box>
            <Box m={1} p={2}>
                <Typography variant={'h5'}>Notifikacije</Typography>
            </Box>
            <CommonSearch cleanAfterSearch={false} searchValue={searchProps.searchValue} onSearch={handleSearch} />
            <Box m={1} p={2}>
                {isLoading ? (
                    <CircularProgressShared />
                ) : (
                    <Box>
                        {pageableKeywordSubscriptionNotification && pageableKeywordSubscriptionNotification.total.elements ? (
                            <Box>
                                {pageableKeywordSubscriptionNotification?.hits.map((keywordSubscriptionNotification: KeywordSubscriptionNotification) => (
                                    <NotificationInfoCard
                                        key={keywordSubscriptionNotification.uuid}
                                        keywordSubscriptionNotification={keywordSubscriptionNotification}
                                        onUpdated={handleNotificationUpdated}
                                    />
                                ))}
                                <ListPagination
                                    onPageClick={handlePageClick}
                                    onSizeSelect={handleSizeSelect}
                                    totalElements={pageableKeywordSubscriptionNotification.total.elements}
                                    pageNumber={searchProps.pageNumber}
                                    pageSize={searchProps.pageSize}
                                />
                            </Box>
                        ) : (
                            <EmptyState message={'Nema pronađenih rezultata'} icon={<ListAlt />} />
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default NotificationsPage;
