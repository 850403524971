import type { FC } from 'react';
import { useEffect } from 'react';
import { Box, Drawer, Theme, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import MenuItems from './MenuItems';

interface IDashboardNavbarProps {
    onMobileClose: () => void;
    openMobile: boolean;
}

const DashboardSidebar: FC<IDashboardNavbarProps> = (props) => {
    const { onMobileClose, openMobile } = props;

    const location = useLocation();

    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'Background.paper',
                        height: 'calc(100% - 64px) !important',
                        top: '64px !important',
                        width: 140,
                    },
                }}
                variant="permanent">
                <Box>
                    <MenuItems />
                </Box>
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onMobileClose}
            open={openMobile}
            PaperProps={{
                sx: {
                    backgroundColor: 'background.paper',
                    top: '64px !important',
                    width: 140,
                },
            }}
            variant="temporary">
            <Box>Menu items mobile</Box>
        </Drawer>
    );
};

export default DashboardSidebar;
