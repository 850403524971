import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { getUnit } from '../../api/units';
import { Unit } from 'revengerz-oss-client';
import UnitDetailsSimpleCard from '../../components/units/UnitDetailsSimpleCard';
import UnitEncumbranceSheetCCard from '../../components/units/UnitEncumbranceSheetCCard';
import OwnershipSheetBCard from '../../components/units/OwnershipSheetBCard';
import PossessionSheetCard from '../../components/units/PossessionSheetCard';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from '@mui/icons-material';
import CircularProgressShared from '../../components/shared/CircularProgressShared';

const UnitDetailsPage: FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { lrUnitId } = params;

    const [unit, setUnit] = useState<Unit>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (lrUnitId) {
            setIsLoading(true);
            getUnit(+lrUnitId).then((result: Unit) => {
                setUnit(result);
                setIsLoading(false);
            });
        }
    }, [lrUnitId]);

    const navigateBack = () => {
        navigate(-1);
    };

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" mt={2} mb={4}>
                <Typography variant="h5">Detalji o čestici</Typography>
                <Button startIcon={<ArrowLeft />} variant="outlined" onClick={navigateBack}>
                    Povratak
                </Button>
            </Box>
            {isLoading ? (
                <CircularProgressShared />
            ) : (
                <Box>
                    {unit && <UnitDetailsSimpleCard unit={unit} />}
                    {unit && unit.encumbranceSheetC && <UnitEncumbranceSheetCCard encumbranceSheetC={unit.encumbranceSheetC} />}
                    {unit && unit.ownershipSheetB && <OwnershipSheetBCard ownershipSheetB={unit.ownershipSheetB} />}
                    {unit && unit.possessionSheetA1 && <PossessionSheetCard possessionSheet={unit.possessionSheetA1} title={'Posjedovnica (I. odjeljak)'} />}
                    {unit && unit.possessionSheetA2 && <PossessionSheetCard possessionSheet={unit.possessionSheetA2} title={'Posjedovnica (II. odjeljak)'} />}
                </Box>
            )}
        </Box>
    );
};

export default UnitDetailsPage;
