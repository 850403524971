import type { FC } from 'react';
import { LrEntry, LrOwner, LrUnitShare, SubShare } from 'revengerz-oss-client';
import { Box, Card, CardContent, Typography } from '@mui/material';
import LabelDescriptionPair from '../shared/LabelDescriptionPair';
import '../../styles/shared.css';
import LrEntryCard from './LrEntryCard';
import SubShareCard from './SubShareCard';
import LrOwnerValue from './LrOwnerValue';
import LabelValuePair from '../shared/LabelValuePair';

interface ILrUnitShareCardProps {
    lrUnitShare: LrUnitShare;
}

const LrUnitShareCard: FC<ILrUnitShareCardProps> = (props) => {
    const { lrUnitShare } = props;

    return (
        <Card className={'content-card'}>
            <CardContent>
                {lrUnitShare.description && <Typography variant={'body1'}>{lrUnitShare.description}</Typography>}
                {lrUnitShare.condominiums && (
                    <LabelDescriptionPair label={'Opis etaže'} description={lrUnitShare.condominiums?.reduce((acc, value) => acc + value, '') || ''} />
                )}
                <Box>{lrUnitShare.lrEntries?.map((lrEntry: LrEntry, index: number) => <LrEntryCard key={index} lrEntry={lrEntry} />)}</Box>
                <Box>{lrUnitShare.subShares?.map((subShare: SubShare, index: number) => <SubShareCard key={index} subShare={subShare} />)}</Box>
                <Box>{lrUnitShare.lrOwners?.map((lrOwner: LrOwner, index: number) => <LrOwnerValue key={index} lrOwner={lrOwner} />)}</Box>
                {lrUnitShare.publicOrCommonGood && <LabelValuePair label={'Javno dobro'} value={lrUnitShare.publicOrCommonGood} />}
            </CardContent>
        </Card>
    );
};

export default LrUnitShareCard;
