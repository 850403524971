import type { FC } from 'react';
import { LrEntry, LrOwner, SubShare } from 'revengerz-oss-client';
import { Box, Card, CardContent, Typography } from '@mui/material';
import '../../styles/shared.css';
import LrOwnerValue from './LrOwnerValue';
import LrEntryCard from './LrEntryCard';
import LabelValuePair from '../shared/LabelValuePair';

interface ISubShareCardProps {
    subShare: SubShare;
}

const SubShareCard: FC<ISubShareCardProps> = (props) => {
    const { subShare } = props;

    return (
        <Card className={'content-card'}>
            <CardContent>
                <Box m={1}>
                    <Typography variant={'body1'}>{subShare.description}</Typography>
                </Box>
                <Box m={1}>
                    <Typography variant={'body1'}>{subShare.condominiums?.reduce((acc, value) => acc + value, '') || ''}</Typography>
                </Box>
                <Box>{subShare.lrOwners?.map((lrOwner: LrOwner, index: number) => <LrOwnerValue key={index} lrOwner={lrOwner} />)}</Box>
                <Box>{subShare.lrEntries?.map((lrEntry: LrEntry, index: number) => <LrEntryCard key={index} lrEntry={lrEntry} />)}</Box>
                {subShare.publicOrCommonGood && <LabelValuePair label={'Javno dobro'} value={subShare.publicOrCommonGood} />}
            </CardContent>
        </Card>
    );
};

export default SubShareCard;
