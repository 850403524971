import type { FC } from 'react';
import { useState } from 'react';
import { Outlet } from 'react-router';
import { DashboardLayoutContainer, DashboardLayoutContent, DashboardLayoutRoot, DashboardLayoutWrapper } from './CommonLayout';
import DashboardSidebar from './DashboardSidebar';
import DashboardToolbar from './DashboardToolbar';

const MainLayout: FC = () => {
    const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);

    return (
        <DashboardLayoutRoot>
            <DashboardToolbar onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(prevValue => !prevValue)} />
            <DashboardSidebar onMobileClose={(): void => setIsSidebarMobileOpen(false)} openMobile={isSidebarMobileOpen} />
            <DashboardLayoutWrapper>
                <DashboardLayoutContainer>
                    <DashboardLayoutContent>
                        <Outlet />
                    </DashboardLayoutContent>
                </DashboardLayoutContainer>
            </DashboardLayoutWrapper>
        </DashboardLayoutRoot>
    );
};

export default MainLayout;
