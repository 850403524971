import ossAxios, { refreshRequest } from './axiosConf';
import { TokenHelper } from '../utils/token-helper';

export const USER_TOKEN = 'oss-user-token';
const USERNAME = 'oss-username';

export default {
    clearLoginData() {
        localStorage.removeItem(USER_TOKEN);
        localStorage.removeItem(USERNAME);
        ossAxios.update();
    },

    getIsRefreshInProgress() {
        return this.isRefreshTokenInProgress;
    },

    getToken(): Token | null {
        const tokenString = localStorage.getItem(USER_TOKEN);
        return tokenString ? JSON.parse(tokenString) : null;
    },

    getUserName(): string | null {
        return localStorage.getItem(USERNAME);
    },

    async isLoggedIn() {
        const token: Token | null = JSON.parse(localStorage.getItem(USER_TOKEN) as string);
        if (!token) {
            return Promise.resolve(false);
        }

        if (TokenHelper.isTokenExpirationDateValid()) {
            return Promise.resolve(true);
        }

        await refreshRequest();
        return Promise.resolve(TokenHelper.isTokenExpirationDateValid());
    },

    isRefreshTokenInProgress: false,

    setIsRefreshInProgress(isRefreshInProgress: boolean) {
        this.isRefreshTokenInProgress = isRefreshInProgress;
    },

    setToken(token: Token) {
        localStorage.removeItem(USER_TOKEN);
        localStorage.setItem(USER_TOKEN, JSON.stringify(token));
        ossAxios.update();
    },

    setUserName(userName: string) {
        localStorage.setItem(USERNAME, userName);
    },
};

export interface Token {
    accessToken: string,
    refreshToken: string
}
