import type { FC } from 'react';
import { ParcelPart } from 'revengerz-oss-client';
import { Card, CardContent, Typography } from '@mui/material';
import ParcelAreaValue from './ParcelAreaValue';
import '../../styles/shared.css';

interface IParcelPartValueProps {
    parcelPart: ParcelPart;
}

const ParcelPartValue: FC<IParcelPartValueProps> = (props) => {
    const { parcelPart } = props;

    return (
        <Card className={'content-card'}>
            <CardContent>
                <Typography>{parcelPart.name}</Typography>
                <ParcelAreaValue area={parcelPart.area} areaInHvat={parcelPart.areaInHvat} areaInAcres={parcelPart.areaInAcres} />
            </CardContent>
        </Card>
    );
};

export default ParcelPartValue;
