import type { FC } from 'react';
import { LrOwnerSimple } from 'revengerz-oss-client';
import { Box, Typography } from '@mui/material';

interface ILrOwnerValueProps {
    lrOwner: LrOwnerSimple;
}

const LrOwnerSimpleValue: FC<ILrOwnerValueProps> = (props) => {
    const { lrOwner } = props;
    const lrOwnerText = [lrOwner.name, lrOwner.taxNumber, lrOwner.address].filter(e => e !== undefined).join(' · ');

    return (
        <Box m={1}>
            <Typography variant={'body1'}>
              <strong>{lrOwnerText}</strong>
            </Typography>
        </Box>
    );
};

export default LrOwnerSimpleValue;
