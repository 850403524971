import { AppSettings } from '../common/app-settings';
import { Token, USER_TOKEN } from '../auth/auth';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { isBefore } from 'date-fns';

export interface ICustomJwtPayload extends JwtPayload {
    family_name: string;
    given_name: string;
    preferred_username: string;
    realm_access: {
        roles: string[]
    };
}

export class TokenHelper {

    public static getUsernameFromToken(token: ICustomJwtPayload): string {
        const preferredUsername = token.preferred_username;
        if (preferredUsername) {
            window.localStorage.setItem(AppSettings.USER_UUID, preferredUsername);
        }
        return preferredUsername;
    }

    public static isTokenExpirationDateValid(): boolean {
        const token: Token | null = JSON.parse(localStorage.getItem(USER_TOKEN) as string);
        if (!token) {
            return false;
        }

        const decodedToken: ICustomJwtPayload = jwtDecode(token?.accessToken ?? '');
        if (!decodedToken.exp) {
            return false;
        }

        return isBefore(new Date(Date.now()), new Date(decodedToken.exp * 1000));
    }

    public static getUserRoles(): string[] {
        const token: Token | null = JSON.parse(localStorage.getItem(USER_TOKEN) as string);
        if (!token) {
            return [];
        }

        const decodedToken: ICustomJwtPayload = jwtDecode(token?.accessToken ?? '');
        if (!decodedToken.exp) {
            return [];
        }

        return decodedToken.realm_access.roles;
    }
}
