import type { FC } from 'react';
import { Box, Typography } from '@mui/material';
import '../../styles/shared.css';

interface ILabelValuePairProps {
    label: string;
    value: string;
}

const LabelValuePair: FC<ILabelValuePairProps> = (props) => {
    const { label, value } = props;

    return (
        <Box className={'label-value-box'}>
            <Box className={'label-value-box-label'}>
                <Typography variant={'overline'}>{label}</Typography>
            </Box>
            <Box>{value ? <Typography variant={'body1'}>{value}</Typography> : '-'}</Box>
        </Box>
    );
};

export default LabelValuePair;
