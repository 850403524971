import {
    FindSubscriptionNotificationsChangeTypeEnum,
    FindSubscriptionNotificationsSortDirectionEnum,
    FindSubscriptionNotificationsSortParameterEnum, KeywordSubscriptionNotification,
    PageableListKeywordSubscriptionNotification,
} from 'revengerz-oss-client';
import apis from '../auth/apis';
import toast from 'react-hot-toast';
import { AxiosResponse } from 'axios';

export const findSubscriptionNotifications = async (
    pageNumber: number,
    pageSize: number,
    sortParameter: FindSubscriptionNotificationsSortParameterEnum,
    sortDirection: FindSubscriptionNotificationsSortDirectionEnum,
    subscriptionUuid?: string,
    searchString?: string,
    read?: boolean,
    changeType?: FindSubscriptionNotificationsChangeTypeEnum
): Promise<PageableListKeywordSubscriptionNotification> => {
    return apis
        .keywordSubscriptionNotificationApi()
        .findSubscriptionNotifications(pageNumber, pageSize, sortParameter, sortDirection, subscriptionUuid, searchString, read, changeType)
        .then((response) => response.data)
        .catch((error) => {
            toast.error('Oops. There seems to be an error.');
            throw error;
        });
};

export const deleteSubscriptionNotification = async (
    uuid: string
): Promise<AxiosResponse<void>> => {
    return apis
        .keywordSubscriptionNotificationApi()
        .deleteSubscriptionNotification(uuid)
        .catch((error) => {
            toast.error('Oops. There seems to be an error.');
            throw error;
        });
};

export const markNotificationAsRead = async (
    uuid: string
): Promise<KeywordSubscriptionNotification> => {
    return apis
        .keywordSubscriptionNotificationApi()
        .updateSubscriptionNotification(uuid, { read: true })
        .then((response) => response.data)
        .catch((error) => {
            toast.error('Oops. There seems to be an error.');
            throw error;
        });
};
