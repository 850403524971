import type { FC, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';

interface IEmptyStateProps {
    message: string;
    icon: ReactNode;
}

const EmptyState: FC<IEmptyStateProps> = (props) => {
    const { message, icon } = props;

    return (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
            <Box sx={{ mb: 10, mt: 15, opacity: '0.3', transform: 'scale(4)' }}>{icon}</Box>
            <Typography sx={{ mb: 3 }} color={'textInfo'} variant={'subtitle2'}>
                {message}
            </Typography>
        </Box>
    );
};

export default EmptyState;
