import type { FC } from 'react';
import { LrOwner } from 'revengerz-oss-client';
import { Box, Typography } from '@mui/material';
import LrEntrySimpleCard from './LrEntrySimpleCard';

interface ILrOwnerValueProps {
    lrOwner: LrOwner;
}

const LrOwnerValue: FC<ILrOwnerValueProps> = (props) => {
    const { lrOwner } = props;
    const lrOwnerText = [lrOwner.share, lrOwner.name, lrOwner.taxNumber, lrOwner.address].filter(e => e !== undefined).join(' · ');

    return (
        <Box m={1}>
            {lrOwner.lrEntry && <LrEntrySimpleCard lrEntrySimple={lrOwner.lrEntry} />}
            <Typography variant={'body1'}>
                <Box fontWeight={'bold'}>{lrOwnerText}</Box>
            </Typography>
        </Box>
    );
};

export default LrOwnerValue;
