import type { FC } from 'react';
import { Loyalty, NotificationsOutlined, PersonOutlined, Search } from '@mui/icons-material';
import { Box, Divider } from '@mui/material';
import '../../styles/shared.css';
import CustomMenuItem, { IMenuItem } from './CustomMenuItem';
import { ProtectedRoutes } from '../../routes';

const MenuItems: FC = () => {
    const menuItems: IMenuItem[] = [
        {
            icon: <Search />,
            label: 'Pretraga',
            routePath: ProtectedRoutes.units_route.path,
        },
        {
            icon: <Loyalty />,
            label: 'Pretplate',
            routePath: ProtectedRoutes.subscriptions_route.path,
        },
        {
            icon: <NotificationsOutlined />,
            label: 'Notifikacije',
            routePath: ProtectedRoutes.notifications_route.path,
        },
        {
            icon: <PersonOutlined />,
            label: 'Osobe',
            routePath: ProtectedRoutes.people_route.path,
        },
    ];

    return (
        <Box m={1}>
            {menuItems.map((value, index) => (
                <Box key={index}>
                    <CustomMenuItem menuItem={value} />
                    <Divider />
                </Box>
            ))}
        </Box>
    );
};

export default MenuItems;
