import type { FC } from 'react';
import { ActivePlumb, Unit } from 'revengerz-oss-client';
import { Box, Card, CardContent, Typography } from '@mui/material';
import CustomCardHeader from '../shared/CustomCardHeader';
import LabelValuePair from '../shared/LabelValuePair';
import '../../styles/shared.css';

interface IUnitDetailsSimpleCardProps {
    unit: Unit;
}

const UnitDetailsSimpleCard: FC<IUnitDetailsSimpleCardProps> = (props) => {
    const { unit } = props;

    return (
        <Card className={'content-card'}>
            <CustomCardHeader text={'Detalji'} />
            <CardContent>
                <Box borderBottom={'1px solid lightgrey'} p={2}>
                    {!unit.verificated && (
                        <Box>
                            <Typography color={'red'}>ZK ULOŽAK NIJE VERIFICIRAN!</Typography>
                        </Box>
                    )}
                    <Box>
                        <Typography>
                            {unit.lrUnitId > 2500000 ? 'Z.K. uložak br.' : 'KPU poduložak br.'} {unit.lrUnitNumber}, k.o. {unit.mainBookName}
                        </Typography>
                    </Box>
                    {unit.lrUnitTypeName && <Box>{unit.lrUnitTypeName.toUpperCase()}</Box>}
                </Box>
                {unit.institutionName && <LabelValuePair label={'Općinski sud / ZK odjel'} value={unit.institutionName} />}
                {unit.lastDiaryNumber && <LabelValuePair label={'Broj zadnjeg dnevnika'} value={unit.lastDiaryNumber} />}

                {unit.activePlumbs && (
                    <Box className={'label-value-box'}>
                        <Box className={'label-value-box-label'}>
                            <Typography>Aktivne plombe</Typography>
                        </Box>
                        <Box>
                            {unit.activePlumbs.map((plumb: ActivePlumb) => (
                                <Typography key={plumb.fileNumber}>
                                    {plumb.fileNumber} {plumb.plumbMark ? plumb.plumbMark : ''}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default UnitDetailsSimpleCard;
