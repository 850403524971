import ClientOAuth2 from 'client-oauth2';

export class AppSettings {

    public static SETTINGS_LS_KEY = 'settings';

    /**
     * Custom cookies used in every http request to API
     */
    public static REFRESH_TOKEN_COOKIE: string = 'refresh_token';
    public static USER_UUID: string = 'user_uuid';

    /**
     * Get oAuth client config object, used in components for
     * fetching auth code and access token afterwards
     */
    public static get OAUTH_CLIENT(): ClientOAuth2 {
        return new ClientOAuth2({
            accessTokenUri: process.env.REACT_APP_ACCESS_TOKEN_URL,
            authorizationUri: process.env.REACT_APP_AUTHORIZATION_URL,
            clientId: process.env.REACT_APP_CLIENT_ID,
            clientSecret: process.env.REACT_APP_CLIENT_SECRET,
            redirectUri: `${process.env.REACT_APP_REDIRECT_URL}/redirect`,
        });
    }

    public static get OAUTH_SCOPES(): string {
        return process.env.REACT_APP_OAUTH_SCOPES as string;
    }
}
