import type { ChangeEvent, FC } from 'react';
import { Box, Card, MenuItem, Pagination, Select, SelectChangeEvent, Typography } from '@mui/material';

interface IUnitsListPaginationProps {
    onPageClick: (page: number) => void;
    onSizeSelect: (size: number) => void;
    totalElements: number;
    pageNumber: number;
    pageSize: number;
}

const ListPagination: FC<IUnitsListPaginationProps> = (props) => {
    const { onPageClick, onSizeSelect, totalElements, pageNumber, pageSize } = props;

    const pagesCount = Math.ceil(totalElements / pageSize);

    const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
        onPageClick(value);
    };

    const handleSizeChange = (event: SelectChangeEvent<number>) => {
        const value = event.target.value;

        if (value) {
            onSizeSelect(+value);
        }
    };

    return (
        <Card sx={{ p: 1, m: 1 }}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography mx={2}>
                    Ukupno: {totalElements}
                    {totalElements === 10000 ? '+' : ''}
                </Typography>
                <Pagination count={pagesCount} page={pageNumber} onChange={handlePageChange} />
                <Select size={'small'} sx={{ mx: 2 }} value={pageSize} onChange={handleSizeChange} label={'Po stranici'}>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                </Select>
            </Box>
        </Card>
    );
};

export default ListPagination;
