import UnitsPage from './pages/units/UnitsPage';
import { RouteObject } from 'react-router';
import AuthGuard from './components/auth-guard/AuthGuard';
import Login from './pages/authentication/Login';
import { OAuthRefreshPage } from './pages/oauth/OAuthRefreshPage';
import { OAuthRedirectPage } from './pages/oauth/OAuthRedirectPage';
import MainLayout from './components/layouts/MainLayout';
import UnitDetailsPage from './pages/units/UnitDetailsPage';
import SubscriptionsPage from './pages/subscriptions/SubscriptionsPage';
import PeoplePage from './pages/people/PeoplePage';
import NotificationsPage from './pages/notifications/NotificationsPage';

export class UnprotectedRoutes {
    public static unprotected_login_route = {
        path: '/login',
        element: <Login />,
    };

    public static unprotected_refresh_route = {
        path: 'refresh',
        element: <OAuthRefreshPage />,
    };

    public static unprotected_redirect_route = {
        path: 'redirect',
        element: <OAuthRedirectPage />,
    };
}

export class ProtectedRoutes {
    public static units_route = {
        path: '/home/units',
        element: <UnitsPage />,
    };

    public static unit_details_route = {
        path: '/home/units/:lrUnitId',
        element: <UnitDetailsPage />,
    };

    public static subscriptions_route = {
        path: '/home/subscriptions',
        element: <SubscriptionsPage />,
    };

    public static people_route = {
        path: '/home/people',
        element: <PeoplePage />,
    };

    public static notifications_route = {
        path: '/home/notifications',
        element: <NotificationsPage />,
    };
}

const PROTECTED_ROUTES: RouteObject = {
    path: 'home',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        ProtectedRoutes.units_route,
        ProtectedRoutes.unit_details_route,
        ProtectedRoutes.subscriptions_route,
        ProtectedRoutes.people_route,
        ProtectedRoutes.notifications_route,
    ],
};

export const HOME_ROUTE: RouteObject = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <UnitsPage />,
        },
    ],
};

const routes: RouteObject[] = [
    HOME_ROUTE,
    PROTECTED_ROUTES,
    UnprotectedRoutes.unprotected_login_route,
    UnprotectedRoutes.unprotected_redirect_route,
    UnprotectedRoutes.unprotected_refresh_route,
];

export default routes;
