import type { FC } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

interface ICircularProgressSharedProps {
    messageText?: string;
}

const CircularProgressShared: FC<ICircularProgressSharedProps> = (props) => {
    const { messageText } = props;

    return (
        <Box textAlign={'center'} mt={8}>
            <CircularProgress />
            <Typography sx={{ mt: 4 }}>{messageText ? messageText : 'Učitavanje...'}</Typography>
        </Box>
    );
};

export default CircularProgressShared;
