import type { FC } from 'react';
import { KeywordSubscriptionDetails } from 'revengerz-oss-client/src/api';
import { Badge, Box, Card, CardContent, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import '../../styles/shared.css';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';

interface ISubscriptionCardProps {
    subscriptionDetails: KeywordSubscriptionDetails;
    onDeleteSubscriptionClick: (subscriptionUuid: string) => void;
    onEditSubscriptionClick: (keywordSubscriptionDetails: KeywordSubscriptionDetails) => void;
}

const SubscriptionCard: FC<ISubscriptionCardProps> = (props) => {
    const { subscriptionDetails, onDeleteSubscriptionClick, onEditSubscriptionClick } = props;

    return (
        <Card className={'content-card'}>
            <CardContent>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Box width={'100%'}>
                        <Box p={1}>
                            <Typography variant={'body1'}>
                                <strong>{subscriptionDetails.subscription.keyword}</strong>
                            </Typography>
                        </Box>
                        <Divider />
                        <Box p={1}>
                            <Typography variant={'body2'}>{subscriptionDetails.subscription.description}</Typography>
                        </Box>
                        <Divider />
                        <Box p={1} display={'flex'}>
                            <Box mr={2}>
                                <Typography variant={'caption'}>Ukupno: {subscriptionDetails.totalNotifications}</Typography>
                            </Box>
                            <Box mr={4}>
                                <Typography variant={'caption'}>Novo:</Typography>
                                <Badge badgeContent={subscriptionDetails.unreadNotifications} color={'error'} sx={{ ml: 2 }} />
                            </Box>
                        </Box>
                    </Box>

                    <Box display={'flex'} alignItems={'flex-start'}>
                        <Tooltip title={'Uredi'}>
                            <IconButton size={'small'} color={'info'} onClick={() => onEditSubscriptionClick(subscriptionDetails)}>
                                <EditOutlined fontSize={'small'} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={'Obriši'}>
                            <IconButton size={'small'} color={'error'} onClick={() => onDeleteSubscriptionClick(subscriptionDetails.subscription.uuid)}>
                                <DeleteOutline fontSize={'small'} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default SubscriptionCard;
